import { UnoappBusinessBranding, UnoappUser, UnoappUserBusiness } from '../middlewares/auth';
import { readonly, ref, reactive } from 'vue';
import useAppState from './useAppState';
import { HttpClient } from '../utils';

const httpClient = new HttpClient(import.meta.env.VITE_AUTH_API_URL, {
    'api-token': import.meta.env.VITE_AUTH_API_TOKEN,
}).httpClient;

const isLoggedIn = ref(false);
const authToken = ref('');

const initUser: UnoappUser = {
    id: '',
    first_name: '',
    email: '',
    type: '',
}

const authUser = reactive<UnoappUser>(initUser);

const initUserBusiness: UnoappUserBusiness = {
    id: '',
    name: '',
    slug: '',
    admin_access: false,
    is_corporate: false,
}
const activeBusiness = reactive<UnoappUserBusiness>(initUserBusiness);

const initBusinessBranding: UnoappBusinessBranding = {
    business_id: '',
    primary_color: '',
    secondary_color: '',
    accent_color: '',
    logo_url: '',
}
const businessBranding = reactive<UnoappBusinessBranding>(initBusinessBranding);

const myBusinesses = reactive<UnoappUserBusiness[]>([]);

const requireTwoFactor = ref(false);

const { setLoadingMsg, toggleLoading } = useAppState();

export function useAuth() {
    const setIsLoggedIn = (value: boolean) => {
        if (!value) {
            setAuthToken('');
            setAuthUser(initUser);
            setActiveBusiness(initUserBusiness);
            setBusinessBranding(initBusinessBranding);
        }

        isLoggedIn.value = value;
    }

    const getMyBusinesses = async () => {
        const result = await httpClient.get<{
            payload: UnoappUserBusiness[]
        }>('/api/v2/users/businesses?branding=true', {
            headers: {
                'auth-token': authToken.value,
            }
        });

        // if there's set businesses to empty
        if (result.error) {
            myBusinesses.splice(0, myBusinesses.length);
        }

        Object.assign(myBusinesses, result.payload);
    }

    const setAuthUser = (data: UnoappUser) => {
        Object.assign(authUser, data);
    }

    const setActiveBusiness = async (data: UnoappUserBusiness) => {
        setLoadingMsg('Updating account...')
        toggleLoading(true);
        // if setting a new business, make request to api to set business as well
        if (activeBusiness.id !== '' && data.id !== activeBusiness.id) {
            const setBusiness = await httpClient.patch<{ payload: UnoappUserBusiness }>(`/api/v2/users/businesses/${data.id}`, {}, {
                headers: {
                    'auth-token': authToken.value
                }
            });

            if (setBusiness.error) {
                throw new Error('error');
            }

            if (setBusiness.payload.two_factor) {
                requireTwoFactor.value = true;
            }
        }

        if (!requireTwoFactor.value) {
            updateActiveBusiness(data);
        }
        toggleLoading(false);
    }

    const updateActiveBusiness = (data: UnoappUserBusiness) => {
        Object.assign(activeBusiness, data);

        // check if the branding is available in mybusinesses for the selected business and update as needed
        const selectedBusiness = myBusinesses.find((b) => b.id === data.id);

        if (selectedBusiness && selectedBusiness.branding) {
            setBusinessBranding(selectedBusiness.branding);
        }
    }

    const setBusinessBranding = (data: UnoappBusinessBranding) => {
        Object.assign(businessBranding, data);
    }

    const setAuthToken = (value: string) => {
        if (value === '') {
            window.localStorage.removeItem('token');
        }

        authToken.value = value;
    }

    const verifyMultiFactor = async (authCode: number) => {
        const result = await httpClient.post<{ payload: UnoappUserBusiness }>(`/api/v2/auth/multi-factor/verify`, {
            auth_code: authCode,
        }, {
            headers: {
                'auth-token': authToken.value,
            }
        })

        updateActiveBusiness(result.payload);
        requireTwoFactor.value = false;
    }

    const resendMultiFactor = async () => {
        const result = await httpClient.post<{ msg: string }>(`/api/v2/auth/multi-factor/resend`, {}, {
            headers: {
                'auth-token': authToken.value,
            }
        })
    }

    const dismissTwoFactor = () => {
        requireTwoFactor.value = false;
    }

    const logout = async () => {
        const result = await httpClient.post<{ msg: string }>(`/api/v2/auth/logout`, {}, {
            headers: {
                'auth-token': authToken.value
            }
        });

        if (result.error) {
            throw new Error('error logging out');
        }

        setIsLoggedIn(false);
    }

    return {
        isLoggedIn: readonly(isLoggedIn),
        authUser: readonly(authUser),
        activeBusiness: readonly(activeBusiness),
        businessBranding: readonly(businessBranding),
        authToken: readonly(authToken),
        setIsLoggedIn,
        setAuthUser,
        setAuthToken,
        setActiveBusiness,
        setBusinessBranding,
        myBusinesses: readonly(myBusinesses),
        getMyBusinesses,
        logout,
        requireTwoFactor: readonly(requireTwoFactor),
        verifyMultiFactor,
        resendMultiFactor,
        dismissTwoFactor,
    }
}