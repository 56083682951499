import { RouteRecordRaw } from 'vue-router';
import CampaignDetailRoutes from './pages/campaigns/pages/campaign/routes';
const routes: Array<RouteRecordRaw> = [
    {
        path: '/campaigns',
        name: 'CampaignsApp',
        component: () => import(/* webpackChunkName: "CampaignsPage" */ './CampaignsApp.vue'),
        redirect: {
            name: 'CampaignsPage',
        },
        children: [
            {
                path: '',
                name: 'CampaignsPage',
                component: () => import(/* webpackChunkName: "CampaignsPage" */ './pages/campaigns/CampaignsPage.vue'),
            },
            ...CampaignDetailRoutes
        ]
    }
]

export default routes;