<template>
	<div class="relative">
		<button
			@click="emit('pressed')"
			type="button"
			class="w-full inline-block px-6 py-2.5 font-medium text-xs leading-tight uppercase focus:outline-none transition duration-150 ease-in-out"
			:class="[
				isEnabled
					? btnClasses
					: 'bg-btn-disabled text-btn-disabled-text dark:bg-gray-800 dark:text-gray-700 cursor-not-allowed',
				isRounded ? 'rounded' : '',
			]"
			:disabled="!isEnabled"
		>
			<slot name="label" />
		</button>
	</div>
</template>
<script lang="ts" setup>
import { toRefs, ref, onMounted, watch } from 'vue';

const emit = defineEmits<{
	(event: 'pressed'): void;
}>();

const props = withDefaults(
	defineProps<{
		isEnabled?: boolean;
		btnType?: 'primary' | 'secondary' | 'accent' | 'negative';
		isRounded?: boolean;
	}>(),
	{
		isEnabled: true,
		btnType: 'primary',
		isRounded: false,
	}
);

const darkClasses =
	'dark:bg-gray-600 dark:text-gray-400 dark:hover:bg-gray-800';
const btnClasses = ref(
	`bg-btn-primary text-btn-text  hover:bg-btn-primary hover:text-btn-text ${darkClasses}`
);

const { isEnabled, btnType } = toRefs(props);

watch(btnType, () => {
	setButtonClass();
});

const setButtonClass = () => {
	if (btnType.value === 'primary') {
		btnClasses.value = `bg-btn-primary  text-btn-text hover:bg-btn-hover hover:text-btn-text ${darkClasses}`;
	}

	if (btnType.value === 'secondary') {
		btnClasses.value = `bg-btn-secondary text-btn-text hover:bg-btn-secondary hover:text-btn-text ${darkClasses}`;
	}

	if (btnType.value === 'accent') {
		btnClasses.value = `bg-btn-accent text-btn-text hover:bg-btn-accent hover:text-btn-text ${darkClasses}`;
	}

	if (btnType.value === 'negative') {
		btnClasses.value = `bg-red-700 text-white hover:bg-red-700 hover:text-btn-text border-0 ${darkClasses}`;
	}
};

onMounted(() => {
	setButtonClass();
});
</script>
