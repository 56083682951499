import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
   {
      path: ':campaign_id',
      name: 'CampaignPage',
      component: () => import(/* webpackChunkName: "CampaignsPage" */ './CampaignPage.vue'),
      children: [
         {
            path: 'entries',
            name: 'CampaignEntriesPage',
            beforeEnter: (to, from, next) => {
               if (from.name === 'CampaignUserDetailsPage') {
                  to.meta = {
                     refresh: false,
                  }
               }
               next();
            },
            component: () => import(/* webpackChunkName: "CampaignEntriesPage" */ './pages/campaignEntries/CampaignEntriesPage.vue'),
         },
         {
            path: 'entries/:campaign_user_id',
            name: 'CampaignUserDetailsPage',
            component: () => import(/* webpackChunkName: "CampaignUserDetailsPage" */ './pages/campaignEntries/pages/CampaignUserDetailsPage.vue'),
         }
      ]
   },
]

export default routes;