<template>
    <teleport to="body">
        <transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-200 transform"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div v-show="isLoading" class="fixed z-50 inset-0 overflow-y-auto">
                <div
                    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
                >
                    <div
                        class="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 transition-opacity"
                        aria-hidden="true"
                    ></div>
                    <span
                        class="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >&#8203;</span>
                    <div
                        class="inline-block align-bottom bg-transparent text-center overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    >
                        <div
                            class="w-16 h-16 border-b-2 border-white dark:border-gray-400 rounded-full animate-spin inline-block align-middle"
                        ></div>
                        <p class="text-white dark:text-gray-400">{{ loadingMsg }}</p>
                    </div>
                </div>
            </div>
        </transition>
    </teleport>
</template>
<script lang="ts" setup>
import useAppState from "../../composables/useAppState";

const { isLoading, loadingMsg } = useAppState();
</script>