
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { HttpClient } from '../../utils/HttpClient';
import { UnoappBusinessBranding, UnoappUser, UnoappUserBusiness } from './models';
import { useAuth } from '../../composables/useAuth';

const httpClient = new HttpClient(import.meta.env.VITE_AUTH_API_URL, {
    'api-token': import.meta.env.VITE_AUTH_API_TOKEN,
}).httpClient;

export async function verifyAuth(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const { setIsLoggedIn, setAuthUser, authToken, setAuthToken, setBusinessBranding, setActiveBusiness, isLoggedIn } = useAuth();

    if (authToken.value === '') {
        let token = window.localStorage.getItem('token');

        // check the query param for a token
        if (!token) {
            if (to.query.token && typeof (to.query.token) === 'string' && to.query.token !== '') {
                token = to.query.token as string;
            }

            // if the token is present set it in local storage
            if (token) {
                window.localStorage.setItem('token', token);
            }
        }

        if (!token) {
            setIsLoggedIn(false);
            next({
                name: 'AuthErrorPage',
            })
            return;
        }

        setAuthToken(token);
    }

    try {
        const authUser = await getProfile(authToken.value);

        setAuthUser(authUser.user);
        if (authUser.active_business) {
            setActiveBusiness(authUser.active_business);
        } else {
            next({
                name: 'AuthErrorPage',
            })

            return;
        }

        if (authUser.business_branding)
            setBusinessBranding(authUser.business_branding);

        setIsLoggedIn(true);
    } catch (err) {
        setIsLoggedIn(false);
        next({
            name: 'AuthErrorPage',
        })
        return;
    }

    next();
}

async function getProfile(authToken: string, withBranding: boolean = false): Promise<{ user: UnoappUser, active_business?: UnoappUserBusiness, business_branding?: UnoappBusinessBranding }> {
    const result = await httpClient.get<{
        payload: {
            user: UnoappUser, active_business?: UnoappUserBusiness, business_branding?: UnoappBusinessBranding
        }
    }>(`/api/v2/users/profile?branding=${withBranding}`, {
        headers: {
            'auth-token': authToken,
        }
    });

    if (result.error) {
        throw new Error(JSON.stringify(result));
    }

    return result.payload;
}