<template>
    <LoadingSpinner />
    <TwoFactorModal />
    <ErrorModal />
    <div>
        <router-view v-slot="{ Component }">
            <component :is="Component" />
        </router-view>
    </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import useAppState from './composables/useAppState';
import useAppTheme from './themes';
import LoadingSpinner from './components/loading/LoadingSpinner.vue';
import TwoFactorModal from './components/modals/TwoFactorModal.vue';
import ErrorModal from './components/modals/ErrorModal.vue';

const { setTheme } = useAppTheme();
const { toggleLoading, initDarkMode } = useAppState();
toggleLoading(true);
const init = () => {
    // set to dark theme if it's already been set
    initDarkMode();

    // set the default theme
    setTheme({});

    // finish loading
    toggleLoading(false);
}

onMounted(() => {
    // set to loading
    toggleLoading(true);
    // init app
    init();
})
</script>