import { createRouter, createWebHistory, RouteRecordRaw, RouteRecordName } from 'vue-router';
import { verifyAuth } from '../middlewares';
import CampaignsModule from '../modules/campaigns';

const appRoutes: RouteRecordName[] = [];
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'SplashPage',
        beforeEnter: [verifyAuth],
        component: () => import(/* webpackChunkName: "SplashPage" */ '../pages/SplashPage.vue'),
    },
    {
        path: '/error',
        name: 'ErrorPage',
        component: () => import(/* webpackChunkName: "ErrorPage" */ '../pages/ErrorPage.vue'),
    },
    {
        path: '/error/auth',
        name: 'AuthErrorPage',
        component: () => import(/* webpackChunkName: "AuthErrorPage" */ '../pages/AuthErrorPage.vue'),
    },
    ...CampaignsModule
]

for (let x = 0; x < routes.length; x++) {
    if (routes[x].name) {
        appRoutes.push(routes[x].name!)
    }
}

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    // console.log('to fullpath: ', to.fullPath)
    // console.log('to path: ', to.path)
    // console.log('to name: ', to.name)
    // console.log('from fullpath: ', from.fullPath)
    // console.log('fromName: ', from.name)
    // console.log('from: ', from.path)

    // if routing to an error page, go to it
    if (to.fullPath.includes('error')) {
        next();
        return;
    }

    // let routeData = {
    //     from: { ...from },
    //     to: { ...to },
    //     localStorage: { ...window.localStorage },
    // }
    // console.log(routeData)

    // if loading from a full refresh, go to splash route to verify auth
    if (from.path === '/' && !from.name && to.path !== '/') {
        // await verifyAuth(to, from, next);
        next({
            name: 'SplashPage'
        })
        return;
    }

    next();
})

export {
    router,
    appRoutes,
}