<template>
    <teleport to="body">
        <transition enter-active-class="transition ease-out duration-200 transform" enter-from-class="opacity-0"
            enter-to-class="opacity-100" leave-active-class="transition ease-in duration-200 transform"
            leave-from-class="opacity-100" leave-to-class="opacity-0">
            <div v-show="requireTwoFactor" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title"
                role="dialog" aria-modal="true">
                <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                    <!-- This element is to trick the browser into centering the modal contents. -->
                    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <transition enter-active-class="transition ease-out duration-200 transform"
                        enter-from-class="opacity-0" enter-to-class="opacity-100"
                        leave-active-class="transition ease-in duration-200 transform" leave-from-class="opacity-100"
                        leave-to-class="opacity-0">
                        <div
                            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div class="bg-primary dark:bg-gray-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div @click="dismissTwoFactor" class="absolute right-2 top-2 cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        class="h-6 w-6 text-primary-text dark:text-gray-400" fill="none"
                                        viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                                <div class="sm:flex sm:items-start">
                                    <div
                                        class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-primary-dark-50 dark:bg-gray-700 sm:mx-0 sm:h-10 sm:w-10">
                                        <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                        </svg>
                                    </div>
                                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 class="text-lg leading-6 font-medium text-primary-text dark:text-gray-400"
                                            id="modal-title">Multi-Factor Required</h3>
                                        <div class="mt-2">
                                            <p class="text-sm text-primary-text dark:text-gray-400">Enter the
                                                Multi-Factor Auth Code that was sent to your phone number.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-white dark:bg-gray-800 px-4 py-3 flex flex-col">
                                <div class="flex flex-col space-y-1">
                                    <NumberFieldMinimal fieldLabel="Code" :defaultValue="parseInt(multiFactorCode)"
                                        :onChangeCallback="setCode" />
                                    <span class="text-center p-2 font-medium text-primary dark:text-gray-400">
                                        Didn't get the code?
                                        <button type="button" @click="resendMultiFactor"
                                            class="font-bold text-accent hover:text-primary-text-dark-50 dark:text-gray-400 dark:hover:text-gray-600 sm:w-auto sm:text-sm underline">Resend</button>
                                    </span>
                                </div>
                                <button type="button" @click="verify"
                                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary hover:bg-primary-dark-50 dark:bg-gray-900 dark:hover:bg-gray-700 text-base font-medium text-primary-text focus:outline-none sm:ml-3 sm:w-auto sm:text-sm">VERIFY</button>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
    </teleport>
</template>
<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import NumberField from '../inputs/NumberField.vue';
import NumberFieldMinimal from '../inputs/NumberFieldMinimal.vue';
import { useAuth } from '../../composables/useAuth';

const { requireTwoFactor, verifyMultiFactor, resendMultiFactor, dismissTwoFactor } = useAuth();
const multiFactorCode = ref('');

function setCode(value: string) {
    multiFactorCode.value = value;
}

const verify = async () => {
    await verifyMultiFactor(parseInt(multiFactorCode.value));
    multiFactorCode.value = '';
}

onMounted(() => {
})
</script>