<template>
    <div class="relative flex">
        <span v-if="prefix && prefix !== ''"
            class="inline-flex items-center bg-transparent text-txt-primary dark:text-gray-400 shadow-sm text-sm">{{
                prefix
            }}</span>
        <input v-model="numberValue" type="number" :id="fieldId" :placeholder="placeHolder"
            class="peer px-2 py-2 w-full max-w-full text-txt-primary dark:text-gray-400 transition duration-300 focus:border-primary focus:border-b-2 focus:outline-none border-b border-primary dark:border-gray-800 dark:focus:border-gray-400"
            :class="[inputClass]" :required="isRequired" :disabled="isDisabled" />
        <label v-if="fieldLabel && fieldLabel !== ''" :for="fieldId"
            class="absolute peer-placeholder-shown:p-0 scale-75 uppercase left-2 -top-5 text-txt-primary dark:text-gray-400 peer-placeholder-shown:top-2 peer-placeholder-shown:left-0 transition-all text-sm font-semibold peer-focus:scale-75 origin-[0]"
            :class="[labelClass]">{{ fieldLabel }} {{ isRequired ? '*' : '' }}</label>
    </div>
</template>
<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
const fieldId = `text_${Math.random()}`;

const props = withDefaults(defineProps<{
    fieldLabel?: string;
    defaultValue?: number;
    fieldPlaceHolder?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    prefix?: string;
    showBackground?: boolean;
}>(), {
    defaultValue: 0,
    isRequired: true,
    fieldPlaceHolder: '',
    isDisabled: false,
    showBackground: false,
});

const numberValue = ref(props.defaultValue.toString());
const placeHolder = ref(props.fieldPlaceHolder);

const inputClass = ref('placeholder-transparent')
const labelClass = ref('peer-placeholder-shown:text-txt-placeholder dark:peer-placeholder-shown:text-gray-800');

const emit = defineEmits<{
    (event: 'changed', value: number): void;
}>()

watch(numberValue, () => {
    emit('changed', numberValue.value === '' ? 0 : parseInt(numberValue.value))
})

onMounted(() => {
    if (props.isRequired) {
        placeHolder.value += ' *';
    }

    if (props.fieldPlaceHolder !== '') {
        inputClass.value = 'placeholder-txt-placeholder dark:placeholder-gray-800'
        labelClass.value = 'peer-placeholder-shown:text-transparent';
    }

    if (props.showBackground) {
        inputClass.value += ' bg-gray-100 dark:bg-gray-700 rounded-t'
    } else {
        inputClass.value += ' bg-transparent'
    }
})
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
    -moz-appearance: textfield;
    /* Firefox */
}
</style>